import clsx from 'clsx'
import { graphql, PageProps } from 'gatsby'
import * as React from 'react'

import { Page, values } from '@karakuri-ai/faq-component'

import { Maybe, Layout } from '../graphql'
import IndexLayout from '../layouts'
import { traverse } from '../utils/breadcrumb'
import { loadDictionary } from '../utils/dictionary'
import { TagData, Hierarchy, TagPageContext, CategoryDictionary } from '../widgets/interface'
import { renderTagPageWidget } from '../widgets/pages'
import { SEOWidget as SEO } from '../widgets/SEOWidget'

function mainLayout(layout?: Maybe<Maybe<Layout>[]>): Layout[] {
  const defaultLayout: Layout[] = [
    { id: 'navi', type: 'navi' },
    { id: 'tag', type: 'tag' },
  ]
  if (!layout) {
    return defaultLayout
  }
  const main = values(layout, defaultLayout)
  if (main.length === 0) {
    return defaultLayout
  }
  return main
}
function defaultLeft(botId?: Maybe<string>): Layout[] {
  if (botId === 'rarejob' || botId === '5z13fcpat1') {
    return [{ id: 'category', type: 'category' }]
  }
  return []
}
type Props = PageProps<TagData, TagPageContext, { hierarchies?: Hierarchy[] }>
function TagPage({ data, pageContext, location }: Props) {
  const { url, tagCloud } = data.settingYaml
  const [dictionary, setDictionary] = React.useState<CategoryDictionary>({})
  React.useEffect(() => {
    loadDictionary().then(dictionary => setDictionary(dictionary))
  }, [])
  const breadcrumb = {
    current: pageContext.title || pageContext.parentTitle || '',
    url: url || '',
    hierarchies: location.state?.hierarchies
      ? location.state.hierarchies
      : traverse(pageContext.category, dictionary)
          .reverse()
          .reduce<{ title: string; link: string }[]>((acc, h) => {
            acc.push({
              link: `/category/${h.id}`,
              title: h.title,
            })
            return acc
          }, []),
  }

  const widgetProps = {
    data,
    breadcrumb,
    pageContext,
    tagCloud: !!tagCloud,
    location,
    hierarchies: location.state?.hierarchies || breadcrumb.hierarchies,
  }
  const layout = data.settingYaml.layout || 'one'
  const left =
    layout === 'one'
      ? []
      : values(data.settingYaml.tagLayoutLeft, defaultLeft(data.settingYaml.botId))
  const right = layout === 'three' ? values(data.settingYaml.tagLayoutRight, []) : []
  const main = mainLayout(data.settingYaml.tagLayoutMain)

  return (
    <IndexLayout className="faq-category-layout">
      <SEO pathname={location.pathname} title={pageContext.title || pageContext.parentTitle} />
      <Page className="faq-content faq-category-page">
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>{pageContext.title}</h2>
          </div>
          {renderTagPageWidget(main, widgetProps)}
        </div>
        <div className={clsx('faq-left-content', { 'faq-no-content': left.length === 0 })}>
          {renderTagPageWidget(left, widgetProps)}
        </div>
        <div className={clsx('faq-right-content', { 'faq-no-content': right.length === 0 })}>
          {renderTagPageWidget(right, widgetProps)}
        </div>
      </Page>
    </IndexLayout>
  )
}
export default TagPage

export const query = graphql`
  query TagQuery {
    settingYaml(ignore: { ne: true }) {
      botId
      layout
      url
      tagCloud
      tagLayoutMain {
        ...Layout
      }
      tagLayoutLeft {
        ...Layout
      }
      tagLayoutRight {
        ...Layout
      }
      categories {
        id
        title
        sub {
          ...SubRecursive
        }
      }
      waitMs {
        search
      }
      words {
        breadcrumb {
          top
        }
        search {
          resultMessage {
            success
            error
          }
        }
      }
      searchType
      queryThreshold
    }
  }
`
